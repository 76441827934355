import React from "react";

// Lib
import i18n from "../i18n";

const TOUandPP = ({ clientName, tos_uri, policy_uri, handleAnalyticsTrack }) => {

  const renderTOUandPP = () => {
    
    return  (
      <div className="consent-docs-link">
        {i18n.t("consent__doc_links", { clientName })}
        {
          tos_uri
          &&
          <a className="textPrimaryColor" href={tos_uri} target="_blank" rel="noopener noreferrer" onClick={() => { handleAnalyticsTrack("onClick_event", i18n.t("consent__terms_of_use"), "")} }>{i18n.t("consent__terms_of_use")}</a>
        }

        {
          (tos_uri && policy_uri)
          &&
          i18n.t("consent_doc_and")
        }

        {
          policy_uri
          &&
          <a className="textPrimaryColor" href={policy_uri} target="_blank" rel="noopener noreferrer" onClick={() => {handleAnalyticsTrack("onClick_event", i18n.t("consent__privacy_policy"), "")}}>{i18n.t("consent__privacy_policy")}</a>
        }
      </div>
    )
  }

  return (
    <>
      {
        (clientName && (tos_uri || policy_uri))
        &&
        renderTOUandPP()
      }
    </>
  );
}

export default TOUandPP;
